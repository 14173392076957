html {
  font-size: 100%;
}

.customsearch-form {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 0.85rem;
  padding: 2px 0;
}
.customsearch-form [type=search],
.customsearch-form [type=submit] {
  display: inline-block;
}
.customsearch-form [type=search] {
  background: rgba(218, 215, 219, 0.15);
  box-sizing: border-box;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid #DAD7DB;
  color: #3d3d3d;
  text-indent: 6px;
  margin-right: -42px;
  line-height: 36px;
  width: 100%;
  font-size: 1.2rem;
}
.customsearch-form [type=search]::placeholder {
  color: rgba(155, 155, 155, 0.66);
}
.customsearch-form [type=search]:focus {
  background: rgba(218, 215, 219, 0.25);
  border-bottom: 1px solid #9B9B9B;
  outline: none;
}
@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}
.customsearch-form input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
.customsearch-form [type=submit] {
  position: relative;
  cursor: pointer;
  width: 36px;
  color: #9B9B9B;
  background: none;
  border: none;
  padding: 8px;
  top: 4px;
  outline: none;
  transition: all 0.3s linear;
}
.customsearch-form [type=submit]:hover,
.customsearch-form [type=search]:focus + [type=submit] {
  color: #9B9B9B;
}
.customsearch-form .search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0;
  background-color: #DAD7DB;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.customsearch-form .search-results .result-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.customsearch-form .search-results .result-list .result span {
  display: block;
}
.customsearch-form .search-results .result-list .result span.title {
  margin-bottom: 3px;
}
.customsearch-form .search-results .result-list .result span.description {
  font-size: 0.9em;
  color: #9B9B9B;
  line-height: 1.3;
}
.customsearch-form .search-results .facet-results {
  display: flex;
  flex-direction: row;
}
.customsearch-form .search-results .facet-results .facet-title {
  width: 30%;
  padding-right: 0.625rem;
}
.customsearch-form .search-results .facet-results .facet-title h5 {
  padding: 0.625rem 0;
  margin: 0;
  font-size: 0.75rem;
  color: rgba(61, 61, 61, 0.6);
  text-align: right;
}
.customsearch-form .search-results .facet-results .result-list {
  width: 70%;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.customsearch-form .search-results .facet-results .result-list li a {
  font-size: 1rem;
  display: block;
  padding: 0.625rem 1rem;
  text-decoration: none;
  transition: background-color 0.3s linear;
}
.customsearch-form .search-results .facet-results .result-list li.selected a {
  background-color: rgba(255, 255, 255, 0.3);
}